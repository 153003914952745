<template>
  <div
    class="d-flex justify-content-center align-items-center h-100"
    v-if="$store.getters['load']"
  >
    <div
      class="spinner-border text-primary"
      role="status"
      style="width: 3rem; height: 3rem"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div class="state h-100" v-else>
    <div class="overlay h-100">
      <div class="container h-100 py-5">
        <div
          class="row row-wrapper d-flex align-items-center justify-content-center"
        >
          <div class="row py-5 text-center">
            <h1 class="text-capitalize pt-2 pt-md-4 text-white">
              State: {{ this.$store.getters["intro"].states[this.$store.getters["currentState"]].title }}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "State",
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("getStateData", this.$store.getters["intro"].states[this.$store.getters["currentState"]].state_key);
    this.$store.dispatch('prevNavigationStatus', false);
    this.$store.dispatch( 'setInstructions', '' )
    this.$store.dispatch( 'setStartTime')
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch( 'setEndTime' , this.$options.name)
    next()
  }
};
</script>

<style scoped>
.state{
  background: url('~@/assets/images/state-background.jpg') no-repeat center;
  background-size: cover;
}
.overlay{
  background-color: #0000006c;
}
</style>